<template>
  <div class="login-center">
    <login-header :type="type"></login-header>
    <div class="login-center-c">
      <transition name="el-zoom-in-top">
        <div class="login-center-r" v-if="loginShow">
          <div>Welcome!</div>
          <div>{{$t('login.title')}}</div>
          <div>
            <div class="login-center-r-input">
              <input type="text" v-model="userName" :placeholder="$t('login.username')" />
              <i class="iconfont icon-bussiness-man"></i>
            </div>
            <div class="login-center-r-text"></div>
            <div class="login-center-r-input">
              <input type="password" v-model="pwd" :placeholder="$t('login.password')" />
              <i class="iconfont icon-mima"></i>
            </div>
            <div class="login-center-r-text">
              <span v-if="veriOne == 1">{{$t('verification.veriFour')}}</span>
              <span v-if="veriOne == 2">{{$t('verification.veriOne')}}</span>
            </div>
          </div>
          <div>
            <div>
              <el-checkbox v-model="checked"></el-checkbox>
              <span>{{$t('login.preserve')}}</span>
            </div>
            <div @click="goLogin">{{$t('login.logingo')}}</div>
          </div>
        </div>
      </transition>
    </div>
    <login-bottom></login-bottom>
  </div>
</template>

<script>
import { sha256 } from 'js-sha256'
import md5 from "js-md5"
import { setTimeout } from 'timers'
import LoginHeader from "../components/loginHeader.vue"
import loginBottom from "../components/loginBotton.vue"
export default {
  name: 'Home',
  components: {LoginHeader,loginBottom},
  data(){
    return{
      radio: "zh-cn",
      loginShow: false,
      checked: false,
      userName: "",
      pwd: "",
      type: "login",
      veriOne: 100
    }
  },
  mounted(){
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      window.location.href = 'https://www.kisssoul.com/app/'
      // window.location.href = 'http://101.43.161.179/app/'
    } else {
      if(localStorage.checked == 'true'){
        this.checked = true
        this.userName = localStorage.username
        this.pwd = localStorage.password
      }else{
        this.checked = false
        this.userName = ""
        this.password = ""
      }
      setTimeout(()=>{
        this.loginShow = true
      },1500)
      window.addEventListener('keydown',this.keyDown);
    }
  },
  created(){
    if(this.$route.query.servicerId){
      sessionStorage['servicerId'] = this.$route.query.servicerId
    }
  },
  methods: {
    // 回车登录
    keyDown(e){
        //如果是回车则执行登录方法
      if(e.keyCode == 13){
        this.goLogin()
      }
    },
    goLogin(){
      if(this.userName && this.pwd){
        this.$isAxios.axiosGet(this.$isApi.login,{userName:this.userName,passWord:sha256(md5(this.pwd))},(res) =>{
          if(res.data.result == 'Loginfailed'){
            this.veriOne = 1
            sessionStorage["token"] = ''
            return false
          }else{
            if(this.checked){
              localStorage["checked"] = true
              localStorage["username"] = this.userName
              localStorage["password"] = this.pwd
            }else{
              localStorage["checked"] = false
              localStorage["username"] = ""
              localStorage["password"] = ""
            }
            sessionStorage['token'] = res.data.token
            sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
            setTimeout(()=>{
              this.$router.push({
                path: "/page/home"
              })
            },300)
          }
        })
      }else{
        this.veriOne = 2
      }
    }
  }
}
</script>

<style scoped>

.login-center{
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/login_bj.jpg") no-repeat;
  position:fixed;
  top: 0;
  left: 0;
  min-width: 1000px;
  z-index:-10;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  animation: myfirst 1s;
	-webkit-animation: myfirst 1s;
  display: flex;
  align-items: center;
}
@keyframes myfirst{
  from {
    -webkit-filter: blur(30px);
    -moz-filter: blur(30px);
    -o-filter: blur(30px);
    -ms-filter: blur(30px);
    filter: blur(30px);
  }
	to {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }
}
@-webkit-keyframes myfirst{
	from {
    -webkit-filter: blur(30px);
    -moz-filter: blur(30px);
    -o-filter: blur(30px);
    -ms-filter: blur(30px);
    filter: blur(30px);
  }
	to {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }
}
.login-center-c{
  height: 55vh;
  background: -moz-linear-gradient(right, rgba(0,0,0,.4) 0%, rgba(0,0,0,.9) 100%);
  background: -webkit-linear-gradient(right, rgba(0,0,0,.4) 0%, rgba(0,0,0,.9) 100%);
  background: -o-linear-gradient(right, rgba(0,0,0,.4) 0%, rgba(0,0,0,.9) 100%);
  background: -ms-linear-gradient(right, rgba(0,0,0,.4) 0%, rgba(0,0,0,.9) 100%);
  background: linear-gradient(to right, rgba(0,0,0,.4) 0%, rgba(0,0,0,.9) 100%);
  animation: mycenter .5s 1s forwards;
	-webkit-animation: mycenter .5s 1s forwards;
  display: flex;
  flex-direction: row;  /* 子元素横向排列 */
  align-items: center; /*  子元素相对父元素垂直居中 */
  justify-content: flex-end;
}
@keyframes mycenter{
  from{
    width: 1px;
  }
  to{
    width: 100vw;
  }
}
@-webkit-keyframes mycenter{
  from{
    width: 1px;
  }
  to{
    width: 100vw;
  }
}
</style>